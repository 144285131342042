footer>hr {
  display: block;
  margin-inline: auto;
  margin-top: -1px;
  border-top: 1px solid #96ACBC;
}

.footerContainer {
  padding: 60px 24px;
}

.footerContainer {
  position: inherit;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1440px;
  margin-inline: auto;
}

.imageContainer {
  width: 160px;
  height: 160px;
  display: block;
}

.imageContainer img {
  width: inherit;
  height: inherit;
  display: inherit;
}

.contactLinks,
.navigationLinks {
  position: relative;
  padding-top: 40px;
}

.navigationLinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
}

.linkList li>a {
  display: block;
  margin-bottom: 16px;
}

.contactLinks li>a {
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.footerContainer>div>h5 {
  position: absolute;
  top: 0;
  left: 0;
  color: #96ACBC;
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footerContainer ul {
  list-style: none;
}

.subscriptionAndSocialMedia {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

footer .blueButtonLink {
  padding: 14px 20px;
  color: var(--White-Text, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  background: rgba(5, 178, 250, 1);
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.36px;
  border-radius: 50px;
  transition: all 200ms linear;
  text-decoration: none;
}

footer .blueButtonLink:hover {
  background: #00A0E2;
  transition: all 200ms linear;
}

.socialMediaList {
  display: flex;
  flex-flow: row nowrap;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
}

.blueButtonLink[datatype=onlySmall] {
  display: none;
}


@media screen and (max-width: 1280px) {
  .footerContainer {
    padding: 80px 32px;
  }
}

@media screen and (max-width: 1024px) {
  .footerContainer {
    padding: 60px 32px;
  }

  .navigationLinks {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .footerContainer {
    padding: 60px 24px;
  }
}

@media screen and (max-width: 576px) {
  .footerContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .imageContainer {
    width: 104px;
    height: 104px;
    display: block;
  }

  .contactLinks {
    width: 100%;
  }


  .contactLinks h5 {
    left: 50% !important;
    transform: translateX(-50%);
  }


  .contactList {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }

  .subscriptionAndSocialMedia {
    width: 100%;
  }

  .socialMediaList {
    justify-content: center;
  }

  .subscriptionAndSocialMedia .blueButtonLink {
    display: none;
  }


  .blueButtonLink[datatype=onlySmall] {
    display: block;
    width: 100%;
  }

  .contactLinks li>a {
    gap: 6px;
  }
}