.skeleton-button {
    width: 100%;
    height: 50px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 8px;
    animation: shimmer 1.5s infinite linear;
    cursor: not-allowed;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -400px 0;
    }
    100% {
      background-position: 400px 0;
    }
  }
  
  .skeleton-button::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to right,
      #e0e0e0 0%,
      #f8f8f8 50%,
      #e0e0e0 100%
    );
    background-size: 400px 100%;
    animation: shimmer 1.5s infinite linear;
  }
  