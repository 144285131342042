/* Full-page container for password reset */
.password-reset-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

/* Styling for the password reset box */
.password-reset-box {
  background: #fff;
  padding: 30px 65px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.password-reset-box h4 {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
  margin-bottom: 30px;
  font-family: "PoppinsSemibold";
}

/* Input group for password and confirmation fields */
.password-reset-input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
}

.password-reset-input-group label {
  font-size: 0.9rem;
  color: #333;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: "PoppinsRegular";
}

.password-reset-input-group input {
  width: 250px;
  padding: 12px;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid #ddd;
  outline: none;
}

.password-reset-error-message {
  color: #d62822;
  font-size: 0.8rem;
  margin-bottom: 10px;
  font-family: "PoppinsRegular";
}

.password-reset-success-message {
  color: #28a745;
  font-size: 0.8rem;
  margin-bottom: 10px;
  font-family: "PoppinsRegular";
}

/* Submit button */
.password-reset-button {
  width: 100%;
  padding: 12px;
  font-size: .9rem;
  color: #fff;
  background: #4070f4;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
  font-family: "PoppinsRegular";
}

.password-reset-button:hover {
  background: #0e4bf1;
}

.password-reset-button:disabled {
  background: #b0c4f4;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Success message box */
.success-message-box {
  text-align: center;
}

/* Login button */
.login-button {
  width: 100%;
  padding: 12px;
  font-size: .9rem;
  color: #fff;
  background: #28a745;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
  font-family: "PoppinsRegular";
  margin-top: 20px;
}

.login-button:hover {
  background: #218838;
}

.password-reset-box p {
  font-size: 0.9rem;
  color: #333;
  font-family: "PoppinsRegular";
  margin-top: 10px;
}
