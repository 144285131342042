.wrapper {
  padding: 24px;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 278px;
  gap: 24px;
}

.gridContainer aside>article:not(:last-child) {
  margin-bottom: 24px;
}

.articleHeader {
  padding: 16px 24px;
  background-color: #FFF;
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
}

.articleHeader h5 {
  color: rgba(0, 0, 0, 0.85);
  font-family: PoppinsMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.articleBody {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background-color: #FFF;
}

.bodyTitle {
  color: rgba(0, 0, 0, 0.45);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.bodyParraf {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  /* Body/regular */
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.articleBody h3 {
  font-family: PoppinsMedium;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.calendarLink {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  transition: all 200ms linear;
}

.calendarLink:hover {
  background: #F0F0F0;
  transition: all 200ms linear;
}

.calendarLink a {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}

#studyPlan .articleBody {
  padding: 16px;
}

.bodyLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  align-self: unset;
}

.bodyLink a {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
}

.progressBar {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  gap: 8px;
  align-items: center;
}

.progressBar span {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  /* Body/regular */
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.barInProgress {
  display: block;
  background: #F5f5f5;
  height: 8px;
  width: 100%;
  border-radius: 8px;
  position: relative;
}

.barInProgress::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 15%;
  height: 8px;
  border-radius: 8px;
  background: #05B2FA;
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 16px;
  }

  .gridContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      "article"
      "section";
    gap: 12px;
  }

  .gridContainer>section {
    grid-area: section;
  }

  .gridContainer>article {
    grid-area: article;
  }

  .gridContainer aside>article:not(:last-child) {
    margin-bottom: 12px;
  }


  #studyPlan .articleBody {
    padding: 5px 16px;
  }

  .articleHeader {
    padding: 7px 14px 7px 14px;
  }

  .articleBody {
    padding: 7px 14px 7px 14px;
  }

  .bodyTitle,
  .bodyParraf {
    font-size: 12px;
    line-height: 1.5;
  }

  .articleBody h3 {
    font-size: 22px;
  }

  .calendarLink {
    padding: 5px 16px;
  }

  .calendarLink a {
    font-size: 14px;
  }
}