div.register-container form div.form-group {
  width: 100%;
  height: auto;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

div.register-container form div.form-group input {
  width: 100%;
  height: 56px;
  border: 1px solid #96ACBC;
  border-radius: 10px;
  padding: 16px;
}

div.register div.container form div.form-group input::placeholder {
  font-family: PoppinsRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #96ACBC;
}

div.register-container form div.form-sub-group {
  display: flex;
  justify-content: space-between;
  margin-top: -27px;
  margin-bottom: 24px;
}

div.register-container form hr {
  margin-top: 40px;
  margin-bottom: 20px;
  border: 1px solid #96ACBC;
}

div.register-container form>p {
  width: 302px;
  height: 21px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

div.register-container {
  max-width: 428px;
  margin: 0 auto;
  padding: 60px 24px 0px 24px;
}

div.register-container div.container-head {
  margin-bottom: 16px;
}

div.register-container div.container-bottom {
  margin-bottom: 144px;
}

div.register div.image-container img {
  position: relative;
  bottom: -4px;
  width: 100%;
}

/* // Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  div.register div.container {
    max-width: 576px;
    margin: 0px auto;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  div.register div.image-container {
    margin-top: 72px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  div.register {
    width: 100%;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
    background: linear-gradient(180deg, #FFFFFF 42.12%, #E2ECF8 72.95%);
  }

  div.register-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 72px;
  }

  div.register-container div.__container {
    display: flex;
    flex-direction: column;
    max-width: 409px;
    align-items: flex-start;
    margin-left: auto;
    position: relative;
    z-index: 1080;
  }

  div.register-container div.container-head>* {
    text-align: start;
  }

  div.register-container div.container-body {
    width: 100%;
    margin: unset;
  }

  div.register div.image-container {
    position: absolute;
    bottom: 0;
    height: calc(100vh - 205px);
    z-index: 1040;
  }

  div.register div.image-container img {
    max-height: 702px;
    height: 100%;
  }

  div.register div.triangle {
    width: 83vw;
    height: 40%;
    background: white;
    position: absolute;
    bottom: 0;
    right: 0;
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
    z-index: 990;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1200px) and (max-width: 1399.98px) {

  div.register {
    width: 100%;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    background: linear-gradient(180deg, #FFFFFF 42.12%, #E2ECF8 72.95%);
  }

  div.register-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 100px 120px;
    height: 100vh;
  }

  div.register-container div.__container {
    display: flex;
    flex-direction: column;
    max-width: 469px;
    align-items: flex-start;
    margin-left: auto;
    position: relative;
    z-index: 1080;
  }

  div.register-container div.container-head>* {
    text-align: start;
  }

  div.register-container div.container-body {
    width: 100%;
    margin: unset;
  }

  div.register div.image-container {
    position: absolute;
    top: 104px;
    height: calc(100vh - 205px);
    z-index: 1040;
  }

  div.register div.image-container img {
    height: 702px;
  }

  div.register div.triangle {
    width: 83vw;
    height: 40%;
    background: white;
    position: absolute;
    bottom: 0;
    right: 0;
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
    z-index: 990;
  }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media screen and (min-width: 1400px) {

  div.register {
    width: 100%;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: calc(100vh - 104px);
    background: linear-gradient(180deg, #FFFFFF 42.12%, #E2ECF8 72.95%);
    min-height: 802px;
  }

  div.register-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 100px 120px;
    height: 100vh;
  }

  div.register-container div.__container {
    display: flex;
    flex-direction: column;
    max-width: 488px;
    align-items: flex-start;
    margin-left: auto;
    position: relative;
    z-index: 1080;
  }

  div.register-container div.container-head>* {
    text-align: start;
  }

  div.register-container div.container-body {
    width: 100%;
    margin: unset;
  }

  div.register div.image-container {
    position: absolute;
    top: 104px;
    height: calc(100vh - 205px);
    z-index: 1040;
  }

  div.register div.image-container img {
    height: 80vh;
    bottom: -8px;
  }

  div.register div.triangle {
    width: 83vw;
    height: 40%;
    background: white;
    position: absolute;
    bottom: 0;
    right: 0;
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
    z-index: 990;
  }
}