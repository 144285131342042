.wrapper {
  padding: 24px;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 278px;
  gap: 24px;
}

#containerCourse .headerContent {
  padding: 24px;
  background: var(--Neutral-1, #FFF);
  margin-bottom: 8px;
  border: 1px solid var(--Neutral-5, #D9D9D9);
}

.headerContent .contentTop {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  margin-bottom: 24px;
  align-items: center;
}

.contentTop h4 {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.contentTop p,
.headerContent>p {

  color: var(--Black, #000);
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.contentTop p {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
}

.courseCard:not(:last-child) {
  margin-bottom: 8px;
}

.courseCard .cardHeader {
  border: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--Neutral-2, #FAFAFA);
  padding: 11px 16px;
}

.courseCard[data-card="graficos"] .cardHeader {
  background: var(--Sunset-Orange-1, #FFF7E6);
}

.cardHeader .cardTitle {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
}

.cardHeader h5 {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.cardDescription p,
.cardDescription p>strong {
  color: var(--Black, #000);
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: inline-block;
}

.cardDescription p {
  margin-left: 24px;
}

.cardDescription p>strong {
  color: var(--Secondary, #1E73BE);
  font-weight: 700;
}


.cardDescription span {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 12px;
}

section#advance {
  margin-bottom: 24px;
}

.sectionHeader {
  padding: 16px 24px;
  background: var(--Neutral-1, #FFF);
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
}

.sectionHeader h5 {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.sectionBody {
  padding: 24px;
  background: var(--Neutral-1, #FFF);
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
}

.sectionBody>p {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 8px;
}

.methodDescription h5 {

  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.methodDescription span {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 12px;
  display: block;
}

.method {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 8px;
}

.methodNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: 1px solid var(--Primary, #05B2FA);
  background: var(--Primary, #05B2FA);
  color: var(--Neutral-1, #FFF);
  position: relative;
}

.method:not(:last-child) .methodNumber::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -32px;
  left: 15px;
  height: 22px;
  width: 1px;
  background: #05B2FA;
}

.methodNumber>h5 {
  font-family: PoppinsRegular;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.checkboxLabel {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.checkboxLabel label {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.checkboxLabel:not(:last-child) {
  margin-bottom: 8px;
}

.courseCard>.cardBody {
  padding: 16px;
  border-right: 1px solid var(--Neutral-5, #D9D9D9);
  border-bottom: 1px solid var(--Neutral-5, #D9D9D9);
  border-left: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--White, #FFF);
}

.videoContainer {
  width: 100%;
  display: block;
}

.videoContainer:not(:last-child) {
  margin-bottom: 24px;
}

.videoContainer img {
  width: inherit;
  display: inherit;
}



.guideList li {
  padding-top: 8px;
  margin-left: 16px;
  min-height: 32px;
}

.guideList * {
  color: var(--Black, #000);
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.guideList li>strong {
  font-weight: 700;
}

.buttons {
  display: flex;
  gap: 20px;
  padding: 12px 0px;
}

.buttons a,
.buttons button {
  text-align: center;
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 16px;
  text-decoration: none;
  border: none;
}

.buttonLinkWhite {
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
  border: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--Neutral-3, #FFF);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.buttonLinkWhite[aria-disabled] {
  background: var(--Neutral-3, #F5F5F5);
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.buttonLinkBlue {
  border-radius: 2px;
  background: rgba(5, 178, 250, 1);
  color: var(--character-primary-inverse, #FFF);
  padding: 8px 16px;
}

img[data-selected=true] {
  transform: rotate(90deg);
  transition: transform 200ms linear;
}

img[data-selected=false] {
  transform: rotate(0deg);
  transition: transform 200ms linear;
}


@media screen and (max-width: 480px) {
  .wrapper {
    padding: 16px;
  }

  .gridContainer {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
  }

  #advance {
    position: fixed;
    z-index: 1080;
    display: none;
  }

  #studyMethods .sectionHeader {
    padding: 14px 14px 2px 14px;
  }

  #studyMethods .sectionBody {
    padding: 2px 14px 14px 14px;
    overflow-x: hidden;
  }

  .sectionHeader h5 {
    font-size: 14px;
  }

  .sectionBody p {
    font-size: 12px;
  }

  .sectionHeader {
    box-shadow: unset
  }

  .methodDescription h5 {
    font-size: 15px;
    white-space: nowrap;
  }

  .methodDescription span {
    font-size: 12px;
    margin: 0;
    white-space: nowrap;
  }

  .methodNumber::before {
    display: none !important;
  }

  .methodList {
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    position: relative;
    overflow-x: scroll;
  }

  .method {
    margin: 0;
    align-items: center;
    gap: 8px;
  }

  #containerCourse .headerContent {
    padding: 14px;
  }

  .headerContent .contentTop {
    margin-bottom: 12px;
    gap: 4px;
  }

  .contentTop h4 {
    font-size: 24px;
  }

  .cardDescription {
    padding-left: 24px;
  }

  .cardDescription span,
  .cardDescription p {
    display: block;
    margin-left: unset;
  }

  .buttons {
    flex-direction: column-reverse;
    gap: 12px;
    padding-top: 16px;
    padding-bottom: 0px;
  }

}

.videoContainerGroup {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}