.wrapper {
  padding: 24px;
  position: relative;
}

.gridContainer {
  display: grid;
  grid-template-columns: 274px 1fr;
  gap: 24px;
}

aside .containerHead {
  background: var(--Neutral-1, #FFF);
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
  padding: 16px 24px;
}

.containerHead h5 {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.containerHead h5:first-child {
  font-family: PoppinsMedium;
}

.containerHead h5:last-child {
  font-family: PoppinsRegular;
}

aside .containerBody {
  padding: 24px;
  background: var(--Neutral-1, #FFF);
}

.countdownWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px 8px 0px;
}

.countdownWrapper p {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.questionsGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column;
}

.questionsGroup .groupBody {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 4px;
  padding-top: 8px;
}

.groupHeader {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-right: auto;
  align-items: center;
}

.groupHeader p {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  padding: 1px 6px;
  border-radius: 2px;
  border: 1px solid var(--Neutral-4, #F0F0F0);
  background: var(--Neutral-3, #F5F5F5);

  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 0.5px;
  
}


.squareSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  padding: 1px 6px;
  border-radius: 2px;

  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 0.5px;
  border: 1px solid var(--Polar-Green-3, #B7EB8F);
  background: var(--Polar-Green-1, #F6FFED);
}

.questionPreview {
  padding-top: 8px;
  padding-bottom: 4px;
  border-top: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.03));
  margin-top: 8px;
}

.questionPreview p {
  color: var(--Black, #000);
  font-family: PoppinsRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
}

.questionPreview a {
  color: var(--Primary, #05B2FA);
  text-align: center;
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 0;
  text-decoration: none;
}

div[data-selected-questions=true]>svg {
  transform: rotate(90deg);
  transition: transform 200ms linear;
}

div[data-selected-questions=false]>svg {
  transform: rotate(0deg);
  transition: transform 200ms linear;
}

div[data-selected-cases=true]>svg {
  transform: rotate(90deg);
  transition: transform 200ms linear;
}

div[data-selected-cases=false]>svg {
  transform: rotate(0deg);
  transition: transform 200ms linear;
}

/* 
** Section Component
*/

section .sectionContainer {
  padding: 24px;
  background: var(--Neutral-1, #FFF);
}

.question {
  position: relative;
  margin-bottom: 24px;
}

.questionHead {
  background: var(--Secondary, #1E73BE);
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
  width: 100%;
  padding: 4px 24px;
  text-align: center;
}

.questionHead h2 {
  color: var(--White, #FFF);
  font-family: PoppinsMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
}

.questionDescription {
  padding: 24px 0px;
}

.questionDescription p {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}

.answerNumber {
  padding: 4px 24px;
  background: var(--Volcano-8, #AD2102);
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
  margin-bottom: 24px;
  max-width: 300px;
}

.answerNumber h3 {
  color: var(--White, #FFF);
  font-family: PoppinsMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
}

.questionAnswers p,
li.answerItem label {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}

.answerItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 6px;
}

.containerFooterButtons {
  padding-top: 16px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerFooterButtons button[type=button] {
  padding: 8px 16px;
  border-radius: 2px;
  transition: all 250ms linear;
}

.containerFooterButtons button[type=button]:hover span {
  color: white;
  transition: all 250ms linear;
}

.containerFooterButtons button[type=button] span {
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;  
  transition: all 250ms linear;
}

button.getRetroButton {
  border: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--Neutral-1, #FFF);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  transition: all 250ms linear;
}

button.nextQuestionsButton {
  background: var(--Primary, #05B2FA);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  border: none;
  color: white;
}

button.nextQuestionsButton:disabled {
  background: #b3b3b3;
  pointer-events: none;
}


.groupHeader>button {
  background: transparent;
  border: none;
  height: 14px;
  width: 14px;
}

.cliniCasesGroup .groupBody {
  display: flex;
  flex-flow: row wrap;

}


@media screen and (max-width: 480px) {
  .wrapper {
    padding: 16px;
  }

  .gridContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      "aside"
      "section";
    gap: 14px;
  }

  .gridContainer>aside {
    grid-area: aside;
  }

  .gridContainer>section {
    grid-area: section;

  }

  .countdownWrapper {
    padding: 0;
    padding-bottom: 8px;
  }

  .sectionContainer {
    padding: 0 !important;
  }

  .questionDescription {
    padding: 24px 12px;
  }

  .questionDescription p {
    font-size: 14px;
  }

  .questionAnswers {
    padding-left: 12px;
    padding-right: 12px;
  }

  .questionAnswers p,
  li.answerItem label {
    font-size: 14px;
  }

  .containerFooterButtons {
    flex-direction: column-reverse;
    gap: 16px;
    padding-bottom: 16px;
  }

  .containerFooterButtons button {
    width: calc(100% - 32px);
  }
}