@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4070f4;
} */

.container {
  background: #fff;
  padding: 30px 65px;
  border-radius: 12px;
  row-gap: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon-circle {
  height: 75px;
  width: 75px;
  background: #4070f4;
  color: #fff;
  font-size: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-icon {
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.container h4 {
  font-size: 1.25rem;
  color: #333;
  font-weight: 500;
  margin: 20px 0;
  font-family: "PoppinsRegular";
}

.input-field {
  display: flex;
  column-gap: 10px;
  font-family: "PoppinsRegular";
}

.input-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #ddd;
  transition: border-color 0.2s;
}

.input-field input:focus {
  border-color: #4070f4;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.input-field input::-webkit-inner-spin-button,
.input-field input::-webkit-outer-spin-button {
  display: none;
}

form button {
  margin-top: 25px;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  border: none;
  padding: 9px 0;
  cursor: pointer;
  border-radius: 6px;
  pointer-events: none;
  background: #6e93f7;
  transition: all 0.2s ease;
  font-family: "PoppinsRegular";
}

form button.active {
  background: #4070f4;
  pointer-events: auto;
}

form button:hover {
  background: #0e4bf1;
}
