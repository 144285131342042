.fadeInEffect {
  transform: scale(1);
  animation: scale 2s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

.fadeInEffect span.titleWord {
  display: inline-block;
  filter: blur(4px);
  opacity: 0;
  font-family: PoppinsBold;
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.fadeInEffect[data-align="center"] {
  text-align: center;
}

.fadeInEffect[data-align="left"] {
  text-align: start;
}

.fadeInEffect[data-align="right"] {
  text-align: end;
}


.fadeInEffect span.titleWord:not(:last-child) {
  margin-right: 10px;
}

.fadeInEffect span.titleWord:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(7) {
  animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(8) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(9) {
  animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(10) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(11) {
  animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(12) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(13) {
  animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(14) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(15) {
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(16) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(17) {
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadeInEffect span.titleWord:nth-child(18) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 576px) {
  .fadeInEffect span.titleWord {
    font-size: 39px;
    line-height: 1.2;
  }

}