@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

.password-recovery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9fafb;
}

.password-recovery-card {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.password-recovery-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 0.5rem;
    font-family: "PoppinsSemibold";
}

.password-recovery-subtitle {
    font-size: 0.9rem;
    color: #4a5568;
    margin-bottom: 1.5rem;
    font-family: "PoppinsRegular";
}

.password-recovery-login-link {
    color: #3182ce;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: "PoppinsRegular";
}

.password-recovery-email-label {
    display: block;
    font-size: 0.9rem;
    font-weight: 600;
    color: #2d3748;
    text-align: left;
    margin-bottom: 0.5rem;
    font-family: "PoppinsRegular";
}

.password-recovery-email-input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    color: #2d3748;
}

.password-recovery-email-input::placeholder {
    color: #a0aec0;
}

.password-recovery-reset-button {
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #3182ce;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "PoppinsSemibold";
}

.password-recovery-reset-button:hover {
    background-color: #2b6cb0;
}

/* Botón deshabilitado */
.password-recovery-reset-button:disabled {
    background-color: #b0c4de;
    cursor: not-allowed;
    opacity: 0.7;
}

.password-recovery-footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-size: 0.9rem;
    color: #4a5568;
}

.password-recovery-footer-link {
    color: #4a5568;
    text-decoration: none;
    margin: 0 0.5rem;
}

.password-recovery-footer-link:hover {
    color: #2b6cb0;
}

.password-recovery-divider {
    margin: 0 0.5rem;
}
