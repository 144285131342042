.input-password {
  width: 100%;
  height: 56px;
  border: 1px solid #96acbc;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-family: PoppinsRegular, sans-serif;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input-password:focus {
  outline: none;
  border-color: #007bff;
}

.input-password::placeholder {
  font-family: PoppinsRegular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #96acbc;
}

.password-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.password-icon {
  position: absolute;
  right: 16px;
  top: 10%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #596670;
}

.password-validations {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.password-validation {
  display: flex;
  align-items: center;
}

.password-validation span {
  margin-left: 10px;
  font-family: PoppinsRegular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #596670;
}

.validation-icon {
  font-size: 16px;
}

.valid {
  color: green;
}

.invalid {
  color: #96acbc;
}

/* Responsividad */
@media (max-width: 768px) {
  .input-password {
    font-size: 14px;
    padding: 12px;
  }

  .password-icon {
    font-size: 18px;
    right: 12px;
  }

  .password-validation span {
    font-size: 12px;
  }
}
