/*
** Important advice modal
*/

.dialogModal {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.modalContainer {
  padding: 24px;
  border-radius: 2px;
  background: var(--Conditional-pop-over, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  max-width: 440px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2010;
}

.containerHead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.containerHead h5 {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.containerBody {
  padding: 16px;
}

.adviceList {
  margin-left: 22px;
}

.adviceList li,
.containerBody > p {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.adviceList li strong {
  font-weight: 600;
}

.footerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  gap: 1rem;
}

.buttonCancel {
  border-radius: 2px;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  transition: all 200ms linear;
}

.buttonCancel:hover {
  background: #f0f0f0;
  transition: all 200ms linear;
}

.buttonCancel span,
.linkButtonBlue span {
  text-align: center;
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
}

.buttonCancel,
.linkButtonBlue {
  padding: 5px 16px;
  border-radius: 2px;
}

.buttonCancel span {
  color: rgba(0, 0, 0, 0.85);
}

.linkButtonBlue {
  color: var(--character-primary-inverse, #fff);
  background: var(--Primary, #05b2fa);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  text-decoration: none;
  flex-grow: 1;
  border: none;
  font-family: PoppinsRegular;
  font-size: 14px;
  text-align: center;
}

.closeButtonModal {
  display: none;
}

@media screen and (max-width: 480px) {
  .dialogModal {
    position: fixed;
    z-index: 2010;
    top: 0;
    left: 0;
    background: white;
    height: 100dvh;
  }

  .modalContainer {
    padding: 16px;
    border-radius: 2px;
    background: var(--Conditional-pop-over, #fff);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    max-width: 100vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2010;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    width: 100%;
  }

  .containerHead {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-top: auto;
  }

  .containerBody {
    padding: 16px;
    margin-bottom: auto;
  }

  .footerButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    gap: 1rem;
    flex-direction: column-reverse;
  }

  .buttonCancel,
  .linkButtonBlue {
    width: 100%;
  }

  .closeButtonModal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: block;
    padding: 4px;
  }

  .closeButtonModal button {
    width: 28px;
    height: 28px;
    background-color: transparent;
    border: none;
  }
}
