.wrapper {
  padding: 24px;
}

.gridContainer {
  display: grid;
  grid-template-columns: 278px 1fr;
  gap: 24px;
}

.resourceSectionContainer {
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
  border: 1px solid #D9D9D9;
  background: #FFF;
}

.containerHeader {
  padding: 16px 24px;
  border: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--Neutral-1, #FFF);
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
  display: flex;
  justify-content: space-between;
}

.containerHeader>div {
  display: flex;
  align-items: center;
}

.containerHeader p,
.containerHeader label,
.containerHeader select,
.backButton {
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

}

.containerHeader .resourceTitle {
  gap: 12px;
}

.containerHeader .resourseFilters {
  gap: 20px;
}

.resourseFilters select {
  margin-left: 10px;
  padding: 4px 12px;
}

.resourseFilters .turns * {
  display: inline-block;
}


.checkboxLabel {
  margin-left: 1rem;
}

.checkboxLabel label {
  margin-left: 8px;
}

.containerHeader h4 {
  font-family: PoppinsMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.containerBody {
  padding: 16px;
  border-right: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
  background: #FFF;
}

.resourceTitle p {
  font-family: Roboto;
}

.backButton {
  display: none;
}


@media screen and (max-width: 480px) {
  .wrapper {
    padding: 16px;
  }

  .gridContainer {
    display: block;
  }

  .containerHeader {
    flex-direction: column;
    padding: 14px;
  }

  .resourceTitle button {
    background-color: transparent;
    border: none; 
    height: 14px;
    width: 14px;
  }

  .resourceTitle h4 {
    font-size: 14px;
    font-family: PoppinsMedium;
    line-height: 21px;
  }

  .resourceTitle p {
    color: rgba(0, 0, 0, 0.45);
  }

  .containerHeader .resourseFilters {
    padding-top: 20px;
    gap: 16px;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .filter {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .filter select {
    flex-grow: 1;
  }

  .backButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
  }
}