div.main-container {
  background-color: #f3dcdc;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

div.verify-email-container {
  background: #feffff;
  box-shadow: 0px 4px 22px 1px #d3e6f8;
  border-radius: 10px;
  width: 330px;
  height: 518px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

div.verify-img img {
  width: 41px;
  height: 41px;
  margin-top: 26px;
}

div.verify-your-email p {
  font-family: PoppinsBold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  margin-top: 10px;
}
div.we-sent-message {
  margin-top: 20px;
}
div.we-sent-message p {
  font-family: PoppinsRegular;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
div.we-sent-message strong {
  font-family: PoppinsBold;
  font-style: normal;
  font-size: 14px;
}
div.code-fields-container {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
div.input-container {
  width: 40px;
  height: 50px;
}
div.input-container input {
  max-width: 40px;
  min-height: 50px;
  border-left: none;
  border-right: none;
  border-top: none;
  font-family: PoppinsLight;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: black;
  border-bottom-color: #00000026;
}
div.input-container input:focus {
  outline: none !important;
  border-bottom-color: #1e73be;
}
div.verify-button-container button {
  width: 116px;
  height: 33px;
  background: #05b2fa;
  border-radius: 50px;
  border: none;
  font-family: MontserratBold;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  margin-top: 40px;
}
div.verify-button-container button:disabled {
  background: #5a8293;
  color: #ffffff;
  cursor: not-allowed;
  pointer-events: all !important;
}
div.email-verify-footer {
  margin-top: 30px;
}
div.email-verify-footer p {
  font-family: PoppinsRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
}
div.email-verify-footer strong {
  font-family: PoppinsBold;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #1e73be;
  margin-top: 30px;
}

.error-message {
  color: red;
  font-size: 14px;
  font-family: PoppinsBold;
  margin-top: 20px;
  text-align: center;
}

div.input-container input {
  max-width: 40px;
  min-height: 50px;
  border: 1px solid #00000026; /* Borde ligero */
  font-family: PoppinsLight;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: black;
  background-color: #fff; /* Fondo blanco para mayor claridad */
  border-radius: 4px; /* Borde redondeado opcional */
  transition: border-color 0.3s ease;
}

div.input-container input:focus {
  outline: none !important;
  border-color: #1e73be; /* Borde más oscuro al enfocar */
  box-shadow: 0 0 5px rgba(30, 115, 190, 0.5); /* Efecto visual al enfocar */
}



/* // Medium devices (tablets, 768px and up) */
@media screen and (min-width: 750px) {
  div.verify-email-container {
    width: 632px;
    height: 682px;
  }
  div.verify-img img {
    width: 112px;
    height: 112px;
    margin-top: 26px;
  }
  div.verify-your-email p {
    font-size: 28px;
    line-height: 30px;
    margin-top: 20px;
  }
  div.we-sent-message {
    margin-top: 40px;
  }
  div.we-sent-message p {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  div.we-sent-message strong {
    font-family: PoppinsBold;
    font-style: normal;
    font-size: 20px;
  }
  div.code-fields-container {
    width: 80%;
    height: 50px;
  }
  div.verify-button-container button {
    width: 180px;
    height: 45px;
    font-size: 18px;
    margin-top: 40px;
  }
  div.email-verify-footer {
    margin-top: 40px;
  }
  div.email-verify-footer p {
    font-size: 15px;
    line-height: 16px;
  }
  div.email-verify-footer strong {
    font-size: 15px;
    line-height: 16px;
    margin-top: 30px;
  }
}
