.wrapper {
  padding: 24px;
}

.containerHeader {
  border: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--Neutral-1, #FFF);
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
}

.headerTitle {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
}

.headerTitle h5 {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.headerTitle p {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.containerBody {
  padding: 20px;
  background: white;
  border-top: unset;
  border-left: 1px solid var(--Neutral-5, #D9D9D9);
  border-bottom: 1px solid var(--Neutral-5, #D9D9D9);
  border-right: 1px solid var(--Neutral-5, #D9D9D9);
}

.containerBody form {
  display: flex;
  flex-flow: row wrap;
  max-width: 824px;
  column-gap: 24px;
  margin-inline: auto;
}

.formInput {
  padding-bottom: 32px;
  width: 100%;
  max-width: 400px;
}

.formInput label {
  margin-bottom: 8px;
  display: block;
}

.formInput input:disabled {
  background-color: rgba(245, 245, 245, 1);
  outline: none;
}

.formInput input {
  width: inherit;
  min-height: 24px;
  padding: 5px 12px;
  border-radius: 2px;
  border: 1px solid rgba(217, 217, 217, 1);
}

.formInput p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  color: rgba(0, 0, 0, 0.45);
}

.formButton {
  display: block;
  width: 100%;
  text-align: end;
  padding: 12px 0;
}

.formButton button {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  background: var(--dark-blue);
}

.formButton button span {
  font-size: 16px;
  line-height: 24px;
  font-family: PoppinsRegular;
  font-weight: 400;
  color: white;
}

.formButton button:disabled {
  background-color: rgba(245, 245, 245, 1);
  cursor: unset;
}

.formButton button:disabled span {
  color: rgba(0, 0, 0, 0.25);
}

.formInput[data-position="left"] {
  margin-left: auto;
}

@media screen and (max-width: 1120px) {

  .formInput[data-position="left"] {
    margin-left: unset;
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    padding: 16px;
  }
}