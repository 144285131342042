.user-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  padding: 16px;
}

.user-info-modal-container {
  background: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  padding: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'PoppinsRegular', sans-serif;
}

.user-info-modal-title {
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 8px;
  text-align: center;
}

.user-info-modal-subtitle {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.user-info-modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.user-info-modal-form-label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.user-info-modal-form-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  font-family: inherit;
  margin-top: 4px;
}

.user-info-modal-submit-button {
  background-color: #05B2FA;
  color: #FFF;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 200ms linear;
  align-self: center;
  width: 100%;
  margin-top: 20px;
}

.user-info-modal-submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.user-info-modal-submit-button:hover:not(:disabled) {
  background-color: #028bbd;
}

.user-info-modal-error {
  color: red;
  font-size: 12px;
}

/* Media Query para pantallas pequeñas */
@media (max-width: 480px) {
  .user-info-modal-container {
    max-width: 100%;
    padding: 16px;
  }

  .user-info-modal-title {
    font-size: 20px;
  }

  .user-info-modal-subtitle {
    font-size: 14px;
  }

  .user-info-modal-form-label {
    font-size: 12px;
  }

  .user-info-modal-form-input {
    padding: 6px;
  }

  .user-info-modal-submit-button {
    padding: 8px;
    font-size: 14px;
  }
}
