.wrapper {
  padding: 24px;
}

.informationList {
  display: block;
  margin-bottom: 32px;
}

.informationList li:first-child {
  margin-left: 20px;
}

.informationList li:last-child {
  list-style: none;
}

.containerHeader {
  border: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--Neutral-1, #FFF);
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
}
 
.headerTitle {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
}

.headerTitle h5 {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: PoppinsMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.headerTitle p {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}


.containerBody {
  padding: 24px;
  border-right: 1px solid var(--Neutral-5, #D9D9D9);
  border-bottom: 1px solid var(--Neutral-5, #D9D9D9);
  border-left: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--White, #FFF);
}

.adviceInformation {
  width: 75%;
  margin-inline: auto;
}

.adviceInformation h2 {
  color: var(--Black, #000);
  font-family: PoppinsMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: block;
  margin-bottom: 32px;
}


@media screen and (max-width: 576px) {
  .wrapper {
    padding: 12px 16px;
  }

  a[data-size="xl"] {
    display: none !important;
  }

  a[data-size="sm"] {
    display: block !important;
  }

  .containerBody {
    padding: 16px;
  }


  .guideIntroduction {
    width: 100%;
    margin-inline: unset;
  }

  .documentLink {
    display: block;
    margin-top: 16px;
  }
}