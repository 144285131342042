.headerContainer {
  padding: 16px 24px;
  background-color: white;
}

.headerContainer * {
  font-family: PoppinsRegular;
}

.containerTree p {
  color: rgba(0, 0, 0, 0.45);
}

.containerTree p>span {
  color: rgba(0, 0, 0, 0.85) !important;
}

.headerContainer>div:first-child {
  margin-bottom: 12px;
}

.containerGreeting {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.greetings {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 24px;
}

.greetingsUsername h4,
.headerContainer>h4 {
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 1.4;
}


.greetings .imageContainer {
  width: 72px;
  height: 72px;
  display: block;
}

.greetings .imageContainer img {
  width: inherit;
  height: inherit;
  display: inherit;
}

.statsContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 64px;
  justify-content: flex-end;
  align-items: center;
}

.stat {
  text-align: end;
}

.stat>p {
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.45);
  display: block;
  margin-bottom: 4px;
}

.stat>span {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

/* Dashboard Page Body */

.dashboardBody {
  padding: 40px 24px;
}

/* Cards Container */

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(252px, 1fr));
  margin-bottom: 24px;
  gap: 1rem;

}


.resourceCard>.cardHeader {
  padding: 8px 16px;
  text-align: start;
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
  background-color: #1E73BE;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.cardHeader .title {
  color: white;
  margin-left: 4px;
  margin-right: auto;
}

.cardHeader .resourceLink {
  color: rgb(119, 214, 255);
  text-decoration: none;
  font-weight: 500;
}

.cardHeader .title,
.cardHeader .resourceLink {
  font-family: PoppinsRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}


.resourceCard .cardBody {
  padding: 16px;
  background-color: #1E73BE;
  text-align: start;
  min-height: 140px;
}

.cardBody p {
  color: white;
  font-family: PoppinsRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

/* Articles */

.dashboardArticles {
  display: grid;
  grid-template-columns: repeat(2, minmax(536px, 1fr));
  gap: 1rem;
}

.articleContent {
  display: flex;
  flex-flow: row nowrap;
}


.bodyTitle *,
.bodyWrapper * {
  font-family: PoppinsRegular;
  font-size: 14px;
}

.bodyTitle {
  padding: 16px 24px;
  text-align: start;
  background: white;
}

.bodyWrapper {
  padding: 0px 24px 24px 24px;
  text-align: start;
  background: white;
}

.bodyWrapper a {
  text-decoration: none;
  color: #05b2fa;
}

.bodyTitle h5 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.bodyWrapper ul {
  margin-left: 1.5rem;
}

.bodyWrapper>* {
  margin-bottom: 12px;
}

.searchBar {
  display: none;
}

@media screen and (max-width: 1024px) {
  
}


@media screen and (max-width: 576px) {
  .headerContainer {
    padding: 8px 16px 24px 16px;
  }

  .searchBar {
    display: block;
    border-radius: 2px;
    background: var(--Neutral-1, #FFF);
  }

  .searchbarInput {
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid var(--Neutral-5, #D9D9D9);
    padding: 22px 16px 8px 16px;
  }

  .searchbarInput input[type=text] {
    padding: 4px 12px;
    width: 100%;
    border: none;
    max-height: 32px;
  }

  .searchIconButton {
    border: none;
    width: 32px;
    display: block;
    height: 32px;
  }

  .headerContainer>div:first-child {
    margin-bottom: 8px;
  }

  .containerTree {
    margin-bottom: 28px;
  }

  .containerGreeting {
    display: block;
  }

  .containerTree p,
  .containerTree p>span {
    font-size: 10px;
  }

  .greetingsUsername h4,
  .headerContainer>h4 {
    margin-bottom: 25px;
    font-size: 18px;
    text-align: center;
  }

  .greetingsUsername h4 {
    margin-bottom: 8px;
  }

  .greetingsUsername {
    text-align: center;
    margin: 0 auto;
    max-width: 259px;
  }

  .greetings>.imageContainer {
    display: none;
  }

  .statsContainer {
    display: none;
  }

  .dashboardBody {
    padding: 20px 16px;
  }

  .cardsContainer {
    width: 100%;
    overflow-x: auto;
  }

  .cardHeader h2 {
    font-size: 14px;
  }

  .resourceCard .cardBody {
    min-height: 121px;
  }

  .cardBody p {
    font-size: 12px;
  }

  .dashboardArticles {
    width: 100%;
    display: block;
  }

  .dashboardArticles>article:first-child {
    margin-bottom: 16px;
  }

  .articleContent {
    align-items: stretch;
  }

  .articleContent .imageContainer {
    width: 70px;
    background: #FFF;
  }

  .articleContent .imageContainer img {
    width: inherit;
  }

  .articleContent .contentBody {
    padding: 0;
  }

  .bodyTitle {
    padding: 12px 14px 0
  }

  .bodyTitle h5 {
    font-size: 14px;
    line-height: normal;
  }

  .bodyWrapper {
    padding: 0px 14px 6px;
  }

  .bodyWrapper * {
    font-size: 12px;
  }

  .bodyWrapper>a {
    text-align: end;
    display: block;
  }

  body,
  html {
    overflow-y: auto;
  }

}